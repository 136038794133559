import React from 'react';
import InlineSVG from 'react-inlinesvg';

function FJSvg(props) {
  const { src } = props;
  const _props = {
    ...props,
    src: typeof src === 'string' ? src : src.src,
  };

  return <InlineSVG {..._props} cacheRequests={true} />;
}

export default FJSvg;
