import React, { Component, createRef } from 'react';

import SrcFJUtil from '../../../src/util/FJUtil';
import FJUtil from '../../../util/FJUtil';

class MovingList extends Component {
  constructor(props) {
    super(props);
    this.swiperContainer = null;
    this.clientX = null;

    // 记录移动元素的本来大小
    this.containerWidth = 0;

    this.swiperContainerStyle = {};

    this.moveDistance = 0;
    // this.marginLeft = createRef();
    // this.marginLeft.current = 0;

    this.containerClick = createRef();
    this.containerClick.current = false;

    this.childAllowClick = createRef();
    this.childAllowClick.current = true;
    this.state = {
      containerClick: false,
      displayData: [],

      complementDom: [],
      leftComplementDom: [],
      rightComplementDom: [],
    };
  }

  componentDidMount() {
    // onClick={this.containerClickFun}
    // onMouseDown={this.downFun}
    // onMouseEnter={this.onMouseEnter}
    // onMouseLeave={this.handleMouseLeave}
    // onTouchStart={this.touchStart}
    // onTouchMove={this.moveFun}
    // onTouchEnd={() => {
    //   this.handleMouseLeave();
    //   this.upFun();
    // }}
    const isMobile = SrcFJUtil.isMobile();
    if (!isMobile) {
      // this.swiper.addEventListener('mouseleave', ()=> {}, false);
      FJUtil.addEvent(this.swiper, 'mousedown', this.downFun);
      FJUtil.addEvent(this.swiper, 'mouseenter', this.onMouseEnter);
      FJUtil.addEvent(this.swiper, 'mouseleave', this.handleMouseLeave);
    } else {
      FJUtil.addEvent(this.swiper, 'touchstart', this.touchStart);
      FJUtil.addEvent(this.swiper, 'touchmove', this.moveFun);
      FJUtil.addEvent(this.swiper, 'touchend', this.handleMouseLeave);
    }
    setTimeout(() => {
      this.calculateVisibleItems();
    });
  }

  componentWillUnmount() {
    // 组件卸载时清除定时器
    clearInterval(this.timer);

    const isMobile = SrcFJUtil.isMobile();
    if (!isMobile) {
      // this.swiper.addEventListener('mouseleave', ()=> {}, false);
      FJUtil.removeEvent(this.swiper, 'mousedown', this.downFun);
      FJUtil.removeEvent(this.swiper, 'mouseenter', this.onMouseEnter);
      FJUtil.removeEvent(this.swiper, 'mouseleave', this.handleMouseLeave);
    } else {
      FJUtil.removeEvent(this.swiper, 'touchstart', this.touchStart);
      FJUtil.removeEvent(this.swiper, 'touchmove', this.moveFun);
      FJUtil.removeEvent(this.swiper, 'touchend', this.handleMouseLeave);
    }
  }

  isPaused = false;
  marginLeft = 0; // 初始时 li 元素的左边距为 0
  isDragging = false;
  // childAllowClick = false;
  changeState = () => {
    const { direction = 'forward', intervalTime = 1 } = this.props;

    if (direction === 'forward') {
      const nextMarginLeft = this.marginLeft + 100;

      this.marginLeft = nextMarginLeft > 0 ? nextMarginLeft - this.containerWidth - 100 : nextMarginLeft;
      this.isPaused = nextMarginLeft > 0;
    } else {
      const nextMarginLeft = this.marginLeft - 100;

      this.marginLeft =
        nextMarginLeft + this.containerWidth < 0 ? nextMarginLeft + this.containerWidth : nextMarginLeft;

      this.isPaused = nextMarginLeft + this.containerWidth < 0;
    }

    this.swiper.style.transform = `translateX(${this.marginLeft}px)`;
    this.swiper.style.transition = this.isPaused ? 'none' : `transform ${intervalTime}s linear`;
  };

  startAnimation = () => {
    const { intervalTime = 1 } = this.props;
    this.timer = setInterval(() => {
      if (this.swiperContainer) {
        this.swiperContainerStyle = this.swiperContainer.getBoundingClientRect();
        if (this.isPaused) {
          this.isPaused = false;
        }
        // 每秒更新一次 marginLeft 的值，使 li 元素每秒向右移动 100px
        this.changeState();
      }
    }, intervalTime * 1000); // 每秒移动100px
  };

  __drawList() {
    const { list, defaultSlot } = this.props;

    return (
      Array.isArray(list) &&
      list.map((item, index) => {
        return defaultSlot ? defaultSlot(item, index, this.childAllowClick.current, this.closeCallBack) : '';
      })
    );
  }

  // 鼠标移入事件
  onMouseEnter = e => {
    clearInterval(this.timer);
    const rec = this.swiper.getBoundingClientRect();

    // this.childAllowClick.current = true;
    sessionStorage.setItem('childAllowClick', 'true');
    this.isPaused = true;
    this.marginLeft = rec ? Math.ceil(rec.left) : this.marginLeft;

    const { intervalTime = 1 } = this.props;
    this.swiper.style.transition = this.isPaused ? 'none' : `transform ${intervalTime}s linear`;
    this.swiper.style.transform = `translateX(${this.marginLeft}px)`;
  };

  // 鼠标移出事件
  handleMouseLeave = () => {
    this.isPaused = false;

    if (!this.containerClick.current) {
      clearTimeout(this.timer);
      this.startAnimation();
    } else {
      this.containerClick.current = false;
    }
  };

  // 当点击模版时，暂停动画滚动，关闭弹窗时，不恢复动画，当重新移入移出的时候，重启动画
  containerClickFun = e => {
    this.containerClick.current = true;
    // this.setState({
    //   containerClick: true,
    // });
    e.stopPropagation();
    e.preventDefault();
  };

  // 点击关闭全屏后，重新启动动画
  closeCallBack() {
    clearTimeout(this.timer);
    this.startAnimation();
  }

  // 通过计算拿到需要补足的dom元素，给动画补充元素
  calculateVisibleItems = () => {
    // 父容器（布局容器）
    // const container = this.swiperContainer.getBoundingClientRect()
    const { width: containerWidth } = window.getComputedStyle(this.swiperContainer);
    const items = this.swiper.childNodes;

    // 包含滚动元素的容器
    const carousel = this.swiper;

    // 获取滚动的最大距离
    const containerStyle = window.getComputedStyle(this.swiper);

    // 元素移动最大距离，用于重置起始位置
    this.containerWidth = parseFloat(containerStyle.width);

    if (parseFloat(containerStyle.width) <= parseFloat(containerWidth)) {
      return;
    }

    // const firstChild = items[0];

    let totalWidth = 0;

    const { direction = 'forward', list, defaultSlot } = this.props;

    let complementDom = [];
    if (direction === 'forward') {
      // 将最前面的元素补充到最后面去

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const getComputedStyle = window.getComputedStyle(item);
        totalWidth += item.offsetWidth + parseInt(getComputedStyle.marginRight) + parseInt(getComputedStyle.marginLeft);

        if (totalWidth < parseFloat(containerWidth) + item.offsetWidth) {
          complementDom.push(list[i]);
        } else {
          break;
        }
      }
    } else {
      // 将最后面的元素补充到最前面来

      for (let i = 0; i < items.length - 1; i++) {
        const item = items[i];
        const getComputedStyle = window.getComputedStyle(item);
        totalWidth += item.offsetWidth + parseInt(getComputedStyle.marginRight + getComputedStyle.marginLeft);
        if (totalWidth < parseFloat(containerWidth) + item.offsetWidth) {
          complementDom.push(list[i]);
        } else {
          break;
        }
      }
    }

    this.setState({
      complementDom: complementDom,
    });

    clearTimeout(this.timer);
    this.startAnimation();
  };

  __drawComplementDom = () => {
    const { list, defaultSlot } = this.props;

    return this.state.complementDom.map((item, index) => {
      return defaultSlot(item, list.length + index, this.childAllowClick.current, this.closeCallBack);
    });
  };

  // 判断鼠标是否在目标元素内
  isMouseInElement(event) {
    const rect = this.swiperContainerStyle;
    const mouseX = SrcFJUtil.isMobile() ? event.touches[0].clientX : event.clientX;
    const mouseY = SrcFJUtil.isMobile() ? event.touches[0].clientY : event.clientY;
    return (
      mouseX - 5 >= rect.left &&
      mouseX + 5 <= rect.left + rect.width &&
      mouseY - 5 >= rect.top &&
      mouseY + 5 <= rect.bottom
    );
  }

  // 鼠标拖动事件
  downFun = e => {
    e.preventDefault();
    e.stopPropagation();

    this.isDragging = true;
    this.swiper.style.transition = 'none';
    this.swiperContainerStyle = this.swiperContainer.getBoundingClientRect();

    this.clientX = SrcFJUtil.isMobile() ? (e.touches ? e.touches[0].clientX : e.clientX) : e.clientX;
    const eventName1 = SrcFJUtil.isMobile() ? 'touchmove ' : 'mousemove';
    const eventName2 = SrcFJUtil.isMobile() ? 'touchend' : 'mouseup';
    const params = SrcFJUtil.isMobile() ? { passive: true } : false;

    FJUtil.addEvent(this.swiperContainer, eventName1, this.moveFun, params);
    FJUtil.addEvent(this.swiperContainer, eventName2, this.upFun, params);
  };

  touchStart = e => {
    event.preventDefault();
    this.onMouseEnter();

    this.isDragging = true;
    this.swiperContainerStyle = this.swiperContainer.getBoundingClientRect();

    this.clientX = SrcFJUtil.isMobile() ? (e.touches ? e.touches[0].clientX : e.clientX) : e.clientX;
  };

  moveFun = e => {
    if (!this.isDragging) {
      return;
    }
    sessionStorage.setItem('childAllowClick', 'false');
    this.moveDistance =
      (SrcFJUtil.isMobile() ? (e.touches ? e.touches[0].clientX : e.clientX) : e.clientX) -
      this.clientX +
      this.marginLeft;
    this.swiper.style.transform = `translateX(${this.moveDistance}px)`;
    if (!this.isMouseInElement(e)) {
      this.upFun(e);
    }
  };

  upFun = e => {
    // 先判断是否鼠标拖拽超出，超出了就移动回来
    this.resetDragPosition();
    const { intervalTime = 1 } = this.props;
    const rec = this.swiper.getBoundingClientRect();

    this.marginLeft = rec ? rec.left : this.moveDistance;

    const eventName = SrcFJUtil.isMobile() ? 'touchmove' : 'mousemove';
    this.swiper.style.transition = `transform ${intervalTime}s linear`;
    const params = SrcFJUtil.isMobile() ? { passive: true } : false;
    FJUtil.removeEvent(this.swiperContainer, eventName, this.moveFun, params);
    this.FJJSWwiperMove = false;

    setTimeout(() => {
      sessionStorage.setItem('childAllowClick', 'true');
    }, 0);
  };

  // 重置拖动位置，防止超出边界
  resetDragPosition = () => {
    // 拿到没有增加子元素时的全部内容长度 this.containerWidth
    // 拿到元素滚动距离

    const { direction = 'forward' } = this.props;
    if (direction === 'forward') {
      if (this.moveDistance > 0) {
        this.swiper.style.transform = `translateX(-${this.containerWidth}px)`;
      }

      if (this.moveDistance + this.containerWidth < 0) {
        this.swiper.style.transform = `translateX(${this.moveDistance + this.containerWidth}px)`;
      }
    } else {
      if (this.moveDistance + parseFloat(this.containerWidth) <= 0) {
        this.swiper.style.transform = `translateX(${this.moveDistance + this.containerWidth}px)`;
      }

      if (this.moveDistance > 0) {
        this.swiper.style.transform = `translateX(-${this.containerWidth}px)`;
      }
    }
  };

  render() {
    const { direction = 'forward' } = this.state;
    return (
      <div
        style={{ width: '100%', overflow: 'hidden', boxSizing: 'content-box' }}
        ref={e => (this.swiperContainer = e)}
      >
        <div
          onClick={this.containerClickFun}
          ref={e => (this.swiper = e)}
          style={{
            display: 'flex',
            width: 'max-content',
          }}
        >
          {direction !== 'forward' && this.__drawComplementDom()}
          {this.__drawList()}
          {direction === 'forward' && this.__drawComplementDom()}
        </div>
      </div>
    );
  }
}

export default MovingList;
